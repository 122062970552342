import { Component, Prop, Vue } from "vue-property-decorator";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UlBreadcrumbs from "@/components/UlBreadcrumbs.vue";
import UIDialogDelete from "@/components/UlDialogDelete.vue";
import { DEFAULT_TABLE_OPTIONS } from "@/api/request";
import { DeleteRequest, SearchRequest, SortRequest } from "@/api/stamp/request";
import { GetRequest } from "@/api/stamp-preview/request";
import { SearchItem } from "@/api/stamp/response";
import StampSearch from "@/store/stamp/search";
import StampModify from "@/store/stamp/modify";
import StampPreviewGet from "@/store/stamp-preview/get";
import Flash, { ErrorAlert, SuccessAlert } from "@/store/common/flash";
import Sortable, { MoveEvent } from "sortablejs";
import WindowSizeChecker from "@/utils/window-size-checker";
import Loading from "@/store/loading";
import WindowOpen from "@/utils/window-open";

@Component({
  components: {
    UlContentHeader,
    UlBreadcrumbs,
    UIDialogDelete
  }
})
export default class list extends Vue {
  @Prop({ type: String, required: true })
  shopId!: string;

  // ------------
  // 固定値
  // ------------
  // タイトル
  headingMain = "スタンプ";
  headingSub = "Stamps";
  breadCrumbs = [
    { text: "アピール", disabled: true },
    { text: "スタンプ", disabled: true },
    {
      text: "店舗一覧",
      disabled: false,
      to: { name: "stamps" }
    },
    { text: "スタンプカード一覧", disabled: true }
  ];

  // テーブルヘッダ
  tableHeaders = [
    { text: "", always: true, value: "reorder", sortable: false },
    { text: "スタンプタイトル", value: "title", sortable: false },
    { text: "利用店舗", value: "shopNames", sortable: false },
    { text: "有効期限", value: "expiration", sortable: false },
    {
      label: "削除",
      text: "",
      value: "delete",
      sortable: false
    },
    {
      label: "認証コード",
      text: "",
      value: "authCodeWrite",
      sortable: false
    },
    {
      label: "条件設定",
      text: "",
      value: "action",
      sortable: false
    },
    {
      label: "編集",
      text: "",
      value: "edit",
      sortable: false
    },
    {
      label: "プレビュー",
      text: "",
      value: "read",
      sortable: false
    }
  ];

  // ------------

  // ------------
  // 変動値
  // ------------
  // テーブル検索オプション（UIDataTableコンポーネントに渡す）
  tableOptions = DEFAULT_TABLE_OPTIONS;

  // 削除ダイアログの表示有無
  showDialog = false;

  // 削除されるタイトル
  deletingTitle = "";

  // 削除されるスタンプID
  deletingId = 0 as number;

  // ------------

  get loading() {
    return Loading.isLoading;
  }

  /**
   * テーブルに表示するアイテムリスト
   */
  get tableItems() {
    return StampSearch.getItems;
  }

  /**
   * 総件数
   */
  get totalCount() {
    return StampSearch.getTotalCount;
  }

  get isTabletAndPC() {
    return WindowSizeChecker.isTabletAndPC(this);
  }

  get isShowNew() {
    return StampSearch.getWriteFlg === 1;
  }

  get shopName() {
    return this.$route.query.shopName || "";
  }

  /**
   * createdライフサイクルフック（UIDataTableコンポーネントに渡す）
   */
  async created() {
    await this.search();
  }

  /**
   * mountedライフサイクルフック（Sortable化）
   */
  mounted() {
    this.$nextTick(() => {
      const _self = this;

      const mainTable = document.querySelector(
        ".v-data-table.stamps tbody"
      ) as HTMLElement;
      Sortable.create(mainTable, {
        onEnd({ newIndex, oldIndex }) {
          const rowSelected = _self.tableItems.splice(oldIndex as number, 1)[0];
          _self.tableItems.splice(newIndex as number, 0, rowSelected);
          _self.sort().then();
        },
        onMove(evt: MoveEvent, originalEvent: Event): boolean {
          return (
            !evt.dragged.querySelector(".not-sortable") &&
            !evt.related.querySelector(".not-sortable")
          );
        }
      });
    });
  }

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await StampSearch.clearResponse();
    await StampModify.clearResponse();
    await StampPreviewGet.clearResponse();
  }

  /**
   * テーブル行の編集ボタンがクリックされた際のコールバック
   */
  editClickCallback(item: SearchItem) {
    this.$router
      .push({
        name: "stamps-edit",
        params: { id: String(item.id) },
        query: { shopId: this.shopId, shopName: this.shopName }
      })
      .then();
  }

  /**
   * テーブル行の削除ボタンがクリックされた際のコールバック
   */
  deleteClickCallback(item: SearchItem) {
    this.showDialog = true;
    this.deletingTitle = item.title;
    this.deletingId = item.id;
  }

  /**
   * テーブル行の条件設定ボタンがクリックされた際のコールバック
   */
  actionClickCallback(item: SearchItem) {
    this.$router
      .push({
        name: "stamps-setting",
        params: { id: String(item.id) },
        query: { shopId: this.shopId, shopName: this.shopName }
      })
      .then();
  }

  /**
   * テーブル行の認証コードボタンがクリックされた際のコールバック
   */
  authCodeWriteClickCallback(item: SearchItem) {
    let query = {
      authType: "0",
      stampAuthId: String(item.stampAuthId),
      shopName: this.shopName,
      fromStampList: "1"
    };
    this.$router
      .push({
        name: "auth-code-edit",
        params: { id: this.shopId },
        query: query
      })
      .then();
  }

  /**
   * テーブル行のプレビューボタンがクリックされた際のコールバック
   */
  async previewClickCallback(item: SearchItem) {
    await StampPreviewGet.get({ id: item.id } as GetRequest);
    if (StampPreviewGet.isSuccess) {
      WindowOpen.preview(StampPreviewGet.getPreviewUrl);
    } else {
      await Flash.setErrorNow({
        message: StampPreviewGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
  }

  /**
   * ダイアログの削除のコールバック
   */
  async doDeleteOnDialog() {
    await Flash.clear();
    this.showDialog = false;
    if (this.deletingId <= 0) {
      return;
    }

    await StampModify.deleteOne({
      id: this.deletingId
    } as DeleteRequest);
    if (StampModify.isSuccess) {
      await Flash.setSuccessNow({
        message: "スタンプを削除しました。",
        consumePath: ""
      } as SuccessAlert);
      await this.search();
    } else {
      await Flash.setErrorNow({
        message: StampModify.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
  }

  /**
   * 検索処理
   */
  async search() {
    await StampSearch.search(this.createSearchRequest());
    if (!StampSearch.isSuccess) {
      await Flash.setErrorNow({
        message: StampSearch.getMessage,
        showReloadButton: true
      } as ErrorAlert);
    }
  }

  /**
   * 並び替え処理
   */
  async sort() {
    // NEW_DEV-1859 cyber start
    if(this.isShowNew) {
      if (this.loading) {
        return;
      }
      await StampModify.sort(this.createSortRequest());
      if (!StampModify.isSuccess) {
        await Flash.setErrorNow({
          message: StampModify.getMessage,
          showReloadButton: false
        } as ErrorAlert);
      }
    }
    // NEW_DEV-1859 cyber end
  }

  goToNew() {
    this.$router
      .push({
        name: "stamps-new",
        query: { shopId: this.shopId, shopName: this.shopName }
      })
      .then();
  }

  /**
   * dataから検索用のリクエストを作成する
   */
  private createSearchRequest(): SearchRequest {
    return { shopId: Number(this.shopId) } as SearchRequest;
  }

  /**
   * dataから並び替え用のリクエストを作成する
   */
  private createSortRequest() {
    const request = { shopId: Number(this.shopId) } as SortRequest;
    request.ids = this.tableItems.map(i => i.id);
    return request;
  }
}
