import service from "@/api/service";
import {
  SearchRequest,
  DetailRequest,
  SortRequest,
  DeleteRequest,
  RegisterRequest
} from "@/api/stamp/request";
import { SearchResponse, DetailResponse } from "@/api/stamp/response";
import { CommonResponse } from "@/api/response";

/**
 * スタンプ一覧・検索APIをコールします。
 *
 * @param searchRequest スタンプ検索のリクエストボディ
 * @return SearchResponse
 */
export async function search(searchRequest: SearchRequest) {
  const response = await service.post("/search-stamp", searchRequest);
  return response.data as SearchResponse;
}

/**
 * スタンプカード設定情報取得APIをコールします。
 *
 * @param detailRequest スタンプ検索のリクエストボディ
 * @return DetailResponse
 */
export async function detail(detailRequest: DetailRequest) {
  const response = await service.post("/get-stamp", detailRequest);
  return response.data as DetailResponse;
}

/**
 * スタンプカード設定登録更新APIをコールします。
 *
 * @param registerRequest スタンプ検索のリクエストボディ
 * @return CommonResponse
 */
export async function register(registerRequest: RegisterRequest) {
  const response = await service.post("/regist-stamp", registerRequest);
  return response.data as CommonResponse;
}

/**
 * スタンプ並び替え登録更新APIをコールします。
 *
 * @param sortRequest
 * @return CommonResponse
 */
export async function sort(sortRequest: SortRequest) {
  const response = await service.post("/sort-stamp", sortRequest);
  return response.data as CommonResponse;
}

/**
 * スタンプ情報削除APIをコールします。
 *
 * @param deleteRequest
 * @return CommonResponse
 */
export async function deleteOne(deleteRequest: DeleteRequest) {
  const response = await service.post("/delete-stamp", deleteRequest);
  return response.data as CommonResponse;
}
